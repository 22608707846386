@import url("https://fonts.googleapis.com/css2?family=Bona+Nova&family=Poppins:wght@300;400;500;600&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: "Bona Nova", serif;
}
body {
  background-color: #fcde67;
}


.indexContainer {
  display: flex;
  margin: 2.5rem 0;
}


.content {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}

