.card {
    width: 200px;
    border-radius: 1.4rem;
    background-color: #030e12;
    padding: 3px;
  } 



 .card img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  } 


.card h3 {
    color: white;
    font-size: 20px;
    border-radius: 20px;
    padding: 5px;
    background: linear-gradient(to left, black, rgb(255, 255, 15));
  } 

  @media screen and (max-width: 768px) {
    .card{
      width: 220px;
      margin: auto;
    }
  }

  @media screen and (max-width: 1096px) {
    .card{
      width: 220px;
      margin: auto;
      gap: 1rem;
    }
  }

