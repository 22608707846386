
.content {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
  }

  .recipe{
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      text-decoration: none;
      color: blue;
  }

.content img {
    width: 100%;
    height: 600px;
    border-radius: 20px;
    object-fit: cover;
    object-position: center;
  }
  .content .inner-content {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    font-size: 1.5rem;
    padding: 2rem;
    text-align: center;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  }
  .recipe-details {
    width: 70%;
    margin: 2rem auto;
    padding: 25px;
    display: flex;
    background-color: black;
    color: whitesmoke;
    justify-content: space-between;
    align-items: center;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  }
  .ingredients,
  .instructions {
    flex-basis: 70%;
    text-align: justify;
  }
  .ingredients h4 {
    margin-top: 1.5rem;
    font-weight: 550;
  }
  .video {
    width: 60%;
    margin: 3rem auto;
  }

  @media screen and (max-wdth: 768px) {
      .content{
          overflow: hidden;
          margin: auto;
      }

  }

  @media screen and (max-width: 768px) {
    .content img {
        width: 92%;
        height: 600px;
        border-radius: 20px;
        object-fit: cover;
        object-position: center;
      }
      
  }

  @media screen and (max-width: 768px) {
      .inner-content>h1{
          font-size: 2rem;
      }

      .cate{
          color:  blue;
          font-size: 13.5px;
      }
      
  }

  @media screen and (max-width: 768px) {
    .content .inner-content{
        background-color: rgba(255, 255, 255, 0.404);
    }

}
  
@media screen and (max-width: 768px) {
    .recipe-details{

        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }    
}

@media screen and (max-width: 768px) {
    .ingredients>h2{
        color: blue;
    }    
    .ingredients>h4{
        font-weight: 600;
    }
}

@media screen and (max-width: 768px) {
    .instructions>h2{
        color: blue;
    }    
    .instructions>h4{
        font-weight: 600;
    }
}

@media screen and (max-width: 768px) {
    .video{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .video>iframe{
        width: 200px;
    }
    
}