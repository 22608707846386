.main {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .heading h1 {
    color: #030e12;
    font-size: 48px;
    font-weight: 900;
    letter-spacing: 1px;
    padding: 2rem;
    margin: 1rem;
    border-radius: 30px;
    background: linear-gradient(to left, rgb(68, 66, 66), rgb(255, 255, 15));
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  }
.search-bar {
    margin: 1rem 0;
    width: 400px;
    height: 40px;
    border: none;
    outline: none;
    color: black;
    font-weight: 600;
    background: linear-gradient(to left, rgb(68, 66, 66), rgb(255, 255, 15));
    border-radius: 1rem;
    padding: 0.3rem 1rem;
  }


  /* .container {  */
    /* width: 80%;
    margin: 25px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 2rem;
  }   */


  .container{
      width: 100%;
      margin: 25px auto;
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      /* grid-template-columns: 250px 1fr; */
  }

  @media screen and (max-width: 768px) {
    .heading>h1{
      font-size: 2.2rem;
    }
    
  }


  @media screen and (max-width: 768px) {
      .container{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 3rem;
        scale: 0.95;
      }
      .container>h1{
        font-size: 1rem;
        font-family: cursive;
      }
  }

  @media screen and (max-width: 1096px) {
    .container{
      
      display: flex;
      flex-wrap: wrap;
    }
  }

@media screen and (max-width: 768px) {
  .indexContainer{
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 768px;
    justify-content: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .search-bar{
    margin: 1rem 0;
    width: 210px;
    height: 40px;
    border: none;
    outline: none;
    color: black;
    font-weight: 600;
    background-color: yellow;
    border-radius: 1rem;
    padding: 0.3rem 1rem;
  }
}

@media screen and (max-width: 1096px) {
  .indexContainer{
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 768px;
    justify-content: center;
    gap: 1rem;
  }
}