.numBox {
    width: 90px;
    height: 40px;
    background-color: #030e12;
    display: flex;
    border-radius: 3px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0 5px 0 5px;
  }


@media screen and (max-width: 768px) {
    .numBox{
      width: 40px;
      height: 20px;
    }   
}